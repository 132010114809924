import { signIn, signOut, getSession } from 'next-auth/react';
import { setCookie } from 'nookies'
import { useEffect, useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { SetCustomer } from 'thirdparty/Nosto/NostoEvents';
import { setRejoinerCustomer } from 'thirdparty/Rejoiner/Rejoiner.service';
import { useCart } from 'components/applications/cart/hooks/useCart';
import { useRouter, useSearchParams } from 'next/navigation';
import { storageKeys } from 'services/LocalStorage.service';


export const useSignInUser = (email, pwd) => {

  const [global, globalDispatch] = useContext(GlobalContext);
    const { cartData, itemMutation } = useCart();
    const router = useRouter();
    const searchParams = useSearchParams();


    useEffect(() => {

        const error = searchParams.get('error');
        // on Cancellation of loggin in
        if (error === "Callback") {
            router.push("/login")
        } 

    }, [searchParams]);

      async function updateCart(id) {
        if( cartData ){
            await itemMutation.mutateAsync({ 
                customerId: id, 
                cartId: cartData.id, 
                type: "updateCustomer" 
            })
        }
    }

    const globalDispatchEmail = async (email) => {

            await globalDispatch({
                type: "setEmail",
                data: {
                    email
                }
            });
    }

    const signInWithPwd = async (email, pwd) => {        
       
        if (email && pwd) { 
            
        const reactToast = await import("react-toastify");
        const result = await signIn('credentials-password', {
                                                            redirect: false,
                                                            email: email,
                                                            password: pwd
                              });  
      
            if (result.error) {                

                if (result.status === 401) {
                    const url = new URL(location.href);
                    url.searchParams.set('emailID', email);
                    signOut({ callbackUrl: `${url}` });
                }
                else {
                    reactToast.toast.error(result.error);
                    console.log(result.error);
                    signOut();
                }

            } else {
                
                // Check session and update global context after successful login

                const session = await getSession();

                if (session && session?.user) {

                    const { user_id, first_name, last_name, external_customer_id } = session.user ?? null;
                   // const email = global.user.email;

                    await globalDispatch({
                        type: "login",
                        data: session.user
                    });

                    await updateCart(external_customer_id);
                    // set cookie for login provider to hide update password
                    setCookie({}, storageKeys.provider, 'google', { maxAge: 24 * 60 * 60 * 10 * 365, secure: true, path: '/' });
                    setCookie({}, storageKeys.user, JSON.stringify(session.user), { maxAge: 24 * 60 * 60 * 10 * 365, secure: true, path: '/' });
                    SetCustomer({
                            customer : {
                                customer_reference: user_id,
                                email,
                                first_name,
                                last_name,
                            },
                            session : global?.thirdParty?.nostoSession                    
                })

                    setRejoinerCustomer({
                        customer_reference: user_id,
                        email,
                        first_name,
                        last_name,
                    })

                    reactToast.toast("Successfully Logged In")
                    router.push('/account/profile');

            
                } else {
                    reactToast.toast.error("Something went wrong with your login! Please try again.");
                    signOut();
                }
            }
        }
    } 
    
  
            return { signInWithPwd, globalDispatchEmail }
  

}