import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useEffect } from 'react';
import Button from 'components/forms/Button';
import clsx from 'clsx';
import { signIn, signOut, useSession } from 'next-auth/react';
import { GoogleLogo } from 'components/icons/GoogleLogo';
import Loader from "components/feedback/Loader";
import { useSearchParams } from 'next/navigation';


import { RegisterUser, CheckEmail } from 'services/myaccount/AuthContext.service';
import { useSignInUser } from 'custom_hooks/useAuth';

import Style from 'components/applications/my_account/styles/Auth.module.scss';
import utils from 'styles/globals/utils.module.scss';
import OrDivider from 'components/display/Divider/OrDivider';


function OauthCheck({ setShown }) {   

    const  logIn   = useSignInUser();
    const { data: session, status } = useSession();
    const searchParams = useSearchParams();


    useEffect(() => {

         if (status === 'authenticated') {
             const { id, name, email } = session.user;

             if (id && email) {
                logIn.globalDispatchEmail(email);
                const pwd = `AT@${id.substring(0, 10)}.${email.substring(0, 5)}`; //id is the profile id given by gmail.set some pwd to register and login google user everytime with same password
                (session?.user) && checkUser( name, email, pwd );
             }
        }

    }, [session?.user])
    

    const checkUser = async ( name, email, pwd ) => {

        const reactToast = await import("react-toastify");         
        
            CheckEmail(email)
                .then((response) => {

                                if (response.data.exists) {
                                    logIn.signInWithPwd(email, pwd);
                                }
                                else {
                                    submitRegistration( name, email, pwd);
                                }

                            }).catch((error) => {
                                reactToast.toast(error);
                                signOut();
                        });
                        
    }  

    const googleSignInHandler = async () => {
        setShown({ oauthlogin: true, register: false });

        let callBackUrl = "";

        const emailIDExists = searchParams.get('emailID');

        if (emailIDExists) {
            const newUrl = new URL(location.href);
            newUrl.searchParams.delete('emailID');
            callBackUrl = newUrl.href;
        }

        //change callback url without emailID, if 401 then append it in useAuth
        const result = await signIn('google', {...(emailIDExists && { callbackUrl: callBackUrl } )} );     
    };


    const Register = useMutation(async (userData) => {
        return await RegisterUser(userData).then(async (data) => {
           
            //Auto Sigin
            if (data.data.access_token) {

                logIn.signInWithPwd(userData.email, userData.password);
            
            }
           
              
        }).catch( async (err) => {
            console.log(err)
            
            if(typeof err?.response?.data !== "undefined"  && typeof err?.response?.data?.errors !== "undefined" ){
                const reactToast = await import("react-toastify");
                reactToast.toast.error(err?.response?.data?.errors[0]);
            }
        });
    })
  


    const submitRegistration = ( name, email, pwd ) => {
        const [first_name, last_name] = name.split(' ');

        Register.mutate({ 
            email: email, 
            password: pwd,
            password_confirmation: pwd,
            first_name: first_name,
            last_name: (last_name && last_name.length > 1) ? last_name : email, //lastname should be more than 2 chars for backend validation
        });

    }
    return (
        <>            
            <OrDivider isWhite />

             <Button 
                type="button" 
                size="sm"
                variant="outline"
                className={clsx(utils.mb_2, Style.button, Style.googleButton, (status === 'loading') && Style.googleLoading)}
                onClick={googleSignInHandler}
                >
                {(status === 'loading') ? 
                    <Loader isLoading />
                               
                            : 
                     <GoogleLogo /> 
                    
                  
                            }
                <span className={utils.ps_1}>Sign in with Google</span>
                </Button>

            </>

    );
}


OauthCheck.propTypes = {
    setShown: PropTypes.func,
   // redirect: PropTypes.func
}


export default OauthCheck